* {
  margin: 0;
  padding: 0;
  border: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Lato", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  outline: none !important;
}

body a,
body a,
body a:hover,
body a:focus,
body a:active {
  text-decoration: none !important;
}

body a {
  color: #292f33;
  margin: 0;
}

body a:hover {
  color: #23d7b2;
  margin: 0;
}

body p,
body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  color: #292f33;
  margin: 0;
}

body ul {
  text-decoration: none;
  list-style-type: none;
}

.mainDiv {
  position: relative;
  padding: 11rem 0 2rem 0;
  min-height: 100vh;
  background: url("../../assets/images/banners/David51.jpg");
  background-attachment: fixed;
  background-position: center 6rem;
  background-repeat: no-repeat;
  background-size: cover;
}

.mainDiv .centeredText {
  text-align: center;
}

.mainDiv h1,
.mainDiv h4 {
  color: #fff;
}

.mainDiv h4 {
  margin-top: 4rem;
  line-height: 2rem;
}

.mainDiv::after {
  content: " ";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.3;
}

.mainDiv .title {
  position: relative;
  z-index: 10;
}

.innerDiv {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 10vw;
  z-index: 10;
}

.rightDiv {
  width: 35rem;
}

.formWrapper {
  background: #f3f7ed;
  padding: 4rem 1rem;
  width: 30rem;
}

.inputWrapper {
  position: relative;
  padding: 1rem !important;
}

.terms {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 26rem;
}

.terms a {
  color: #00a1f1 !important;
  white-space: nowrap;
}

.button {
  margin-top: 1rem !important;
  background: #00b258 !important;
  color: #fff !important;
}

.button:disabled {
  background: #a9a9a9 !important;
  cursor: pointer !important;
}

.selectInput {
  width: 100%;
}

.modal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.alertModal {
  width: 55vw;
  text-align: center;
}

.alertModal h5 {
  margin: 2rem 1rem;
  line-height: 2rem;
}

.alertModal em {
  text-transform: capitalize;
}

@media (max-width: 768px) {
  .alertModal {
    width: 90vw;
    height: 60vh;
    overflow: scroll;
  }
}

.datePicker {
  width: 100%;
}

.backButton {
  position: relative;
  top: 0.5rem;
}

@media (max-width: 992px) {
  .innerDiv {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
    padding: 0.5rem;
  }
  .rightDiv,
  .formWrapper {
    width: 100%;
  }
  .rightDiv {
    margin-bottom: 2rem;
    text-align: center;
  }
  .terms {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    width: 100%;
  }
  .terms a {
    color: #00a1f1 !important;
    white-space: nowrap;
  }
}
