* {
  margin: 0;
  padding: 0;
  border: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Lato", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  outline: none !important;
}

body a,
body a,
body a:hover,
body a:focus,
body a:active {
  text-decoration: none !important;
}

body a {
  color: #292f33;
  margin: 0;
}

body a:hover {
  color: #23d7b2;
  margin: 0;
}

body p,
body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  color: #292f33;
  margin: 0;
}

body ul {
  text-decoration: none;
  list-style-type: none;
}

.container {
  min-height: 100vh;
}

.container .mainLabel {
  background: radial-gradient(#006633, #00b258);
  padding: 10vh 25vw;
}

.container .mainLabel h4 {
  color: #fff !important;
  font-weight: normal;
  text-align: center;
  line-height: 2rem;
}

.container .headerRow {
  text-align: center;
  color: #444;
  font-weight: 400;
}

.container p {
  font-weight: 600;
}

.container .headerLight {
  color: #fff !important;
}

.container .separator {
  display: block;
  content: "";
  width: 6rem;
  height: 2px;
  margin: 0 auto 3rem auto;
  background: -webkit-gradient(linear, left top, right top, from(#23d7b2), color-stop(33%, #00b258), color-stop(66%, #23d7b2), to(#00b258));
  background: linear-gradient(90deg, #23d7b2 0%, #00b258 33%, #23d7b2 66%, #00b258 100%);
  margin-top: 4rem;
}

.sectionRow {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0.7rem;
}

.sectionColumn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.sectionArticle {
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  width: 45vw;
  margin: 2rem;
  line-height: 2rem;
}

.parallax {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: #222;
  height: 100%;
  background-attachment: fixed;
  background-position: center;
  background-repeat: repeat;
  background-size: cover;
  padding: 1rem 0;
}

.headerRow {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.parallaxOver {
  position: absolute;
  top: 0;
  background: #000;
  opacity: 0.8;
  width: 100%;
  height: 100%;
  color: #fff;
}

.Header3 {
  width: 40vw;
}

.LastDiv {
  text-align: center;
}

.ReadMore {
  position: relative;
  top: 4rem;
  width: 10rem;
  border-radius: 5rem;
  border: 2px solid #fff;
  padding: 0.5rem 1rem;
  color: #fff;
  -webkit-transition: all 0.4s ease-in-out !important;
  transition: all 0.4s ease-in-out !important;
  text-transform: capitalize;
}

.ReadMore:hover {
  background: -webkit-gradient(linear, left top, right top, from(#23d7b2), color-stop(33%, #00b258), color-stop(66%, #23d7b2), to(#00b258));
  background: linear-gradient(90deg, #23d7b2 0%, #00b258 33%, #23d7b2 66%, #00b258 100%);
  color: #fff !important;
  border: none;
}

.carouselImage,
.carouselContainer {
  position: relative;
  width: 100vw;
  height: 90vh;
  background: #292f33;
}

.carouselImage {
  -o-object-fit: contain !important;
     object-fit: contain !important;
}

.carouselOverlay {
  position: absolute;
  top: 0;
  width: 100vw;
  height: 90vh;
  background: #000;
  opacity: 0.3;
}

.carouselCaption {
  margin-bottom: 15vh;
}

.carouselCaption *:not(a) {
  color: #fff !important;
}

.carouselCaption p {
  margin-bottom: 3.5rem !important;
}

.applyButton {
  margin-top: 2rem;
}

.lightIcon {
  color: #f9f9f9;
}

@media (max-width: 576px) {
  .sectionRow {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .sectionArticle {
    width: 90vw;
    text-align: center;
  }
  .CenteredRow {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: center;
    padding-bottom: 2rem;
  }
  .CenteredRow .Brand {
    width: 20vw;
    margin-bottom: 1rem;
  }
  .Header3 {
    width: 90vw;
  }
  .header {
    -webkit-transform: scale(0.7);
            transform: scale(0.7);
  }
  .lightIcon,
  .icon {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
}

@media (max-width: 768px) {
  .headerRow {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .carouselImage {
    -o-object-fit: cover !important;
       object-fit: cover !important;
  }
  .carouselCaption h1 {
    font-size: 1.5rem;
  }
  .carouselCaption h3 {
    font-size: 1rem;
  }
  .carouselCaption p {
    font-size: 0.8rem;
  }
  .container .mainLabel {
    background: radial-gradient(#006633, #00b258);
    padding: 1rem 0.5rem;
  }
  .container .mainLabel h4 {
    color: #fff !important;
    font-weight: normal;
    text-align: center;
    line-height: 2rem;
  }
}
