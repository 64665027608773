.wrapper {
  width: 2rem;
}

.image {
  max-width: 100%;
  max-height: 100%;
  display: block;
  -o-object-fit: contain;
     object-fit: contain;
}
