* {
  margin: 0;
  padding: 0;
  border: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Lato", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  outline: none !important;
}

body a,
body a,
body a:hover,
body a:focus,
body a:active {
  text-decoration: none !important;
}

body a {
  color: #292f33;
  margin: 0;
}

body a:hover {
  color: #23d7b2;
  margin: 0;
}

body p,
body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  color: #292f33;
  margin: 0;
}

body ul {
  text-decoration: none;
  list-style-type: none;
}

.mainDiv {
  padding: 10rem 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-image: url(../../assets/images/banners/services.jpg);
  background-size: contain;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
}

.mainDiv h1 {
  text-align: center;
  background: rgba(255, 255, 255, 0.6);
  padding: 0.5rem;
  border-radius: 1rem;
}

.header {
  background: #006633;
  padding: 1.5rem;
  text-align: center;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.header h5 {
  color: #fff;
}

.sections {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 1.5rem;
  background: rgba(255, 255, 255, 0.8);
  border-bottom: 1px solid #23d7b2;
}

.sections .icon {
  padding-right: 1rem;
  color: #006633;
}

.sections h5 {
  color: darkGreen;
  font-weight: bolder;
  line-height: 2rem;
}

.sections p {
  font-weight: bold;
  line-height: 2rem;
}

.sectionWrapper {
  margin: 4rem 0;
  -webkit-box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.129726), 0px 4px 7px rgba(0, 0, 0, 0.11574);
          box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.129726), 0px 4px 7px rgba(0, 0, 0, 0.11574);
}

@media (min-width: 768px) {
  .sectionWrapper {
    width: 36rem;
  }
}
